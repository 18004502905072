import { useEffect } from "react";
import { useParams } from "react-router-dom";

type Params = {
  id: any;
};

const Memebership = () => {
  const { id } = useParams<Params>();

  useEffect(() => {
    if (id) {
      const redirectUrl = `https://www.djaminn.com/download-djaminn/?redirect_token=${encodeURIComponent(
        id
      )}`;
      window.location.href = redirectUrl; // Redirects user
    }
  }, [id]);

  return <div></div>;
};

export default Memebership;
