import { gql } from "@apollo/client";

export const mutation = gql`
  query CheckDataDeletionRequestStatus($confirmationCode: String!) {
    checkDataDeletionRequestStatus(confirmationCode: $confirmationCode) {
      id
      createdAt
      updatedAt
      provider
      status
      userId
    }
  }
`;
