import { FC } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLazyQuery } from "@apollo/client";
import FormItem from "components/FormItem";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

import { SubmitHandler, useForm } from "react-hook-form";

import { mutation } from "./Mutations";

export interface Props {
  className?: string;
}

type FormType = {
  confirmation_number: string;
};

const MetaAccDeletionStatus: FC<Props> = ({ className = "" }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<FormType>();

  const [checkDeletionStatus, { data, loading, error }] =
    useLazyQuery(mutation);

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    // async request which may result error
    try {
      await checkDeletionStatus({
        variables: { confirmationCode: data.confirmation_number },
        onCompleted() {
          reset();
        },
      });
    } catch (e) {
      // handle your error
    }
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Data Deletion Status - Djaminn</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32 italic">
        <h2 className="my-5 flex items-center font-Montserrat text-xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Account Data Deletion Status
        </h2>
        <div className="flex items-center justify-center">
          <p className="my-5 w-2/3 text-center text-gray-400 flex items-center font-Montserrat text-sm leading-[115%] md:text-sm md:leading-[115%] font-semibold justify-center">
            Go to your facebook settings & privacy &gt; hitting application tab
            and type your mentioned request confirmation number here
          </p>
        </div>

        <div className="max-w-xl mx-auto space-y-6">
          <form
            className="grid grid-cols-1 gap-5 mb-48"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormItem
              label="Enter the confirmation number."
              desc=""
              className="font-Montserrat italic text-center"
            >
              <div className="flex">
                <Input
                  {...register("confirmation_number", {
                    required: "Code is required field.",
                  })}
                  type="text"
                  className="italic text-center"
                />
              </div>

              {errors.confirmation_number?.type && (
                <p className="text-red-600">
                  {`*Confirmation number* is mandatory ${errors.confirmation_number?.message}`}
                </p>
              )}
            </FormItem>

            {data?.checkDataDeletionRequestStatus === null && (
              <div className="flex items-center justify-start space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-red-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                  />
                </svg>

                <div className="font-thin text-xs opacity-70">
                  <span className="bg-red-200 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-800">
                    Data deletion request does not exist. Make sure the
                    confirmation number is correct.
                  </span>
                </div>
              </div>
            )}
            {data?.checkDataDeletionRequestStatus?.status === "COMPLETED" && (
              <div className="flex items-center justify-between space-x-2">
                <div className="font-thin inline-flex items-center gap-2 text-xs opacity-70">
                  <CheckCircleIcon className="h-4 w-4 text-green-600" />
                  <span className="bg-red-100 text-green text-xs font-bold mr-2 px-2.5 py-0.5 rounded  dark:text-green-800">
                    {data?.checkDataDeletionRequestStatus?.status}
                  </span>
                </div>
                <div className="font-thin text-xs opacity-70">
                  <span className="text-xs font-semibold mr-2 px-2.5 py-0.5 rounded text-black dark:text-white">
                    {data?.checkDataDeletionRequestStatus?.updatedAt}
                  </span>
                </div>
              </div>
            )}

            <ButtonPrimary
              type="submit"
              disabled={isDirty === false}
              className="bg-pink-500 shadow-sm hover:bg-pink-400 mt-2 font-Montserrat italic"
            >
              {loading ? (
                <>
                  <svg
                    className={`motion-safe:animate-spin dark:text-white text-black  mr-3 h-5 w-5`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </>
              ) : (
                "Check Deletion status"
              )}
            </ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MetaAccDeletionStatus;
