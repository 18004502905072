import { FC } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ResetPasswordPage from "containers/ResetPasswordPage/ResetPasswordPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import NftDetailPage from "containers/NftDetailPage/NftDetailPage";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageUploadItem from "containers/PageUploadItem";
import PageUploadTrack from "containers/PageUploadTrack";
import PageConnectWallet from "containers/PageConnectWallet";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import AddDjaminnPage from "containers/AddDjaminnPage/AddDjaminnPage";
import SampleListPage from "containers/SampleListPage/SampleListPage";
import UserPage from "containers/UserPage/UserPage";
import UserTracksDetailsPage from "containers/UserTracksDetailsPage/UserTracksDetailsPage";
import SongPage from "containers/SongPage/SongPage";
import ContestPage from "containers/ContestDetailPage/Index";
import ConfirmEmailPage from "containers/confirmEmailPage/ConfirmEmailPage";
import ForgotPasswordPage from "containers/ForgotPasswordPage/ForgotPasswordPage";
import UnsubscribePage from "containers/UnsubscribePage/UnsubscribePage";
import MetaAccDeletionStatusPage from "containers/MetaAccDeletionStatus/MetaAccDeletionStatus";
import MemebershipPage from "containers/Memebership/Memebership";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome3 },
  { path: "/#", exact: true, component: PageHome2 },
  { path: "/home2", exact: true, component: PageHome },
  { path: "/home3", exact: true, component: PageHome2 },
  { path: "/home-header-2", exact: true, component: PageHome },
  { path: "/reel-page/:indexVideo", component: NftDetailPage },
  { path: "/contest/:contestId", component: ContestPage },
  {
    path: "/UserTracksDetailsPage/:indexVideo/:USER_ID",
    component: UserTracksDetailsPage,
  },
  { path: "/play-song/:PROJECT_ID", component: SongPage },
  { path: "/page-collection", component: PageCollection },
  { path: "/page-search", component: PageSearch },
  { path: "/profile", component: AuthorPage },
  {
    path: "/user/:passID/confirmation",
    exact: true,
    component: ConfirmEmailPage,
  },
  {
    path: "/meta-account-deletion-status",
    exact: true,
    component: MetaAccDeletionStatusPage,
  },
  { path: "/user/:passID", component: UserPage },
  { path: "/settings", component: AccountPage },
  { path: "/page-upload-item", component: PageUploadItem },
  { path: "/upload_track", component: PageUploadTrack },
  { path: "/connect-wallet", component: PageConnectWallet },
  { path: "/add-your-djaminn", component: AddDjaminnPage },
  { path: "/sample-list", component: SampleListPage },
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/reset-password", component: ResetPasswordPage },
  { path: "/forgot-password", component: ForgotPasswordPage },
  { path: "/subscription", component: PageSubcription },
  { path: "/unsubscribe", component: UnsubscribePage },
  { path: "/membership/:id", component: MemebershipPage },
];

const Routes: FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route path="/playlists">
          <Redirect to="/" />
        </Route>
        <Route path="/membership">
          <Route path=":id" component={MemebershipPage} />
        </Route>
        <Route component={Page404} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
